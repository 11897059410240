<template>
  <div v-if="preview">
    {{ previewTitle }}
  </div>
  <PreviewEditableCheckbox
    v-else
    v-model:open="isOpen"
    v-bind="$props"
    :options="menuOptions"
    :values="selectedValues"
    :trigger-class="triggerClass"
    @select="onSelect"
    @remove="onRemove"
    @clear="onClear"
    @update:open="$emit('update:open', $event)"
  />
</template>

<script lang="ts" setup>
import { map } from 'lodash-es'
import type { FieldOption, PreviewCheckboxProps, TaskFieldRef } from '#field/types'

defineOptions({
  inheritAttrs: false,
})

type Props = Omit<PreviewCheckboxProps, 'options' | 'values'> & {
  disabled?: boolean
  triggerClass?: (active: boolean) => string[]
  width?: number
  value: string
  options: string
  taskId: string
}

const props = defineProps<Props>()

const isOpen = ref(false)

defineEmits<{
  (e: 'update:open', value: boolean): void
}>()
const { updateTaskCustomField } = useUpdateTask()

const selectedValues = computed<string[]>(() => {
  if (typeof props.value === 'string') {
    return safeParseStringToJSON(props.value ?? '', [])
  }

  return props.value ?? []
})

const menuOptions = computed<Array<FieldOption>>(() => {
  if (typeof props.options === 'string') {
    return safeParseStringToJSON(props.options, [])
  }

  return props.options
})

const selectedOptions = computed(() => {
  return menuOptions.value?.filter((option) =>
    selectedValues.value?.includes(option.value)
  )
})

const onSelect = (value: string, fieldId?: string) => {
  if (!props.taskId || !fieldId) return

  updateTaskCustomField({
    taskIds: [props.taskId],
    fieldId,
    value,
    useSelectedTasks: true,
    addValueIds: [value],
  })
}

const onRemove = (value: string, fieldId?: string) => {
  if (!props.taskId || !fieldId) return

  updateTaskCustomField({
    taskIds: [props.taskId],
    fieldId,
    value: null,
    useSelectedTasks: true,
    removeValueIds: [value],
  })
}

const onClear = (fieldId?: string) => {
  if (!props.taskId || !fieldId) return

  updateTaskCustomField({
    taskIds: [props.taskId],
    fieldId,
    value: null,
    useSelectedTasks: true,
  })
}

const previewTitle = computed(() => {
  const labels = map(selectedOptions.value, 'label')
  return labels.join(', ')
})

defineExpose<TaskFieldRef>({
  open: () => {
    isOpen.value = true
  },
  close: () => {
    isOpen.value = false
  },
  delete: () => {
    onClear()
  },
})
</script>
