<template>
  <FilterSelect
    placeholder="Search option"
    :options="options"
    :title="label"
    :search-attributes="['label']"
    :classes="{
      list: 'max-h-[20.175rem] !pl-0 !pr-0.5 !pb-1 !space-y-1',
      item: '!px-2 !py-1 gap-2 text-gray-900 cursor-pointer rounded-md',
    }"
    @change="onSelect"
    @close="emit('close')"
  >
    <template #item="{ option }">
      <FieldItem
        :checked="checkedIds.includes(option.value)"
        :indeterminate="indeterminateIds.includes(option.value)"
        :text="option.label"
        :style="getFieldOptionPreset(option.color)"
        class-name="py-1 px-2 rounded-md"
        data-test="option-item"
        @change="onSelect(option)"
      />
    </template>
    <template #footer>
      <div
        v-if="!disableSetting && can('dashboard.data.manage_tasks_sections')"
        class="pt-1 px-2"
      >
        <UButton
          class="w-full flex justify-center"
          variant="soft"
          color="gray"
          @click="setSettingTab('tasks')"
        >
          Manage options
        </UButton>
      </div>
    </template>
  </FilterSelect>
</template>

<script lang="ts" setup>
import type { FieldOption, PreviewCheckboxProps } from '#field/types'

const props = defineProps<PreviewCheckboxProps>()

const { can } = useBoardAbility()
const { setSettingTab } = useBoardSettingsNavigator()
const { getCheckedIds, getIndeterminateIds } = useFieldMultipleSelect()

const emit = defineEmits<{
  (e: 'remove', value: string, id?: string): void
  (e: 'select', value: string, id?: string): void
  (e: 'update:open', value: boolean): void
  (e: 'close'): void
}>()

const onSelect = (option: FieldOption) => {
  if (
    indeterminateIds.value?.includes(option.value) ||
    !props.values?.includes(option.value)
  ) {
    return emit('select', option.value, props.fieldId)
  }

  return emit('remove', option.value, props.fieldId)
}

const checkedIds = computed(() => {
  return getCheckedIds({
    fieldId: props.fieldId,
    options: props.options,
    selectedValues: props.values,
  })
})

const indeterminateIds = computed(() => {
  return getIndeterminateIds({
    fieldId: props.fieldId,
    options: props.options,
  })
})
</script>
