<template>
  <div class="relative h-full w-full">
    <DeferredPopover
      ref="popoverRef"
      :ui="{
        width: 'min-w-52 max-w-[280px]',
        wrapper: 'h-full',
        trigger: 'h-full flex items-center',
        base: 'pb-2',
      }"
      :disabled="disabled"
      v-bind="$attrs"
      :trigger-class="triggerClass"
      button-class="flex items-center"
      @update:open="$emit('update:open', $event)"
    >
      <template #content>
        <PreviewPopoverContentCheckbox
          :popover-ref="popoverRef"
          v-bind="$props"
          @select="onSelect"
          @remove="onRemove"
          @close="popoverRef.close()"
        />
      </template>
      <div
        :class="[
          'w-full flex',
          showFullLabel
            ? 'overflow-y-auto scroll-stable max-h-20 minimal-scrollbar'
            : 'items-center',
        ]"
      >
        <ResizeLabels
          :labels="labels"
          :width="actualWidth"
          :get-label-style="(color: FieldOptionColor) => getFieldOptionPreset(color) "
          :show-full="showFullLabel"
        />
        <div
          class="absolute right-1 top-1/2 -translate-y-1/2 flex items-center"
        >
          <UButton
            v-if="props.values?.length && !disabled"
            color="gray"
            variant="ghost"
            size="2xs"
            icon="i-heroicons-x-mark"
            class="group-hover/cell:visible invisible"
            @click.stop="onClear"
          />
          <Icon
            v-else
            name="heroicons:chevron-down"
            class="text-gray-500 group-hover/cell:visible invisible mr-1"
            size="2xs"
          />
        </div>
      </div>
    </DeferredPopover>
  </div>
</template>

<script lang="ts" setup>
import type { FieldOptionColor } from '#field/constant'
import type { FieldLabel, PreviewCheckboxProps } from '#field/types'

type Props = PreviewCheckboxProps & {
  disabled?: boolean
  triggerClass?: (active: boolean) => string[]
  width?: number
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'select', value: string, id?: string): void
  (e: 'remove', value: string, id?: string): void
  (e: 'clear', fieldId?: string): void
  (e: 'update:open', value: boolean): void
}>()

const popoverRef = ref()

const actualWidth = computed(() => {
  return (props.width ?? 0) - 24 // 24 is the width of the close icon
})

const selectedOptions = computed(() => {
  return props.options?.filter((option) =>
    props.values?.includes(option.value)
  )
})

const labels = computed<FieldLabel[]>(() => {
  const labels = selectedOptions.value?.map((option) => {
    return {
      id: option.value,
      name: option.label,
      color: option.color as string,
    }
  })

  return labels || []
})

const onSelect = (value: string, fieldId?: string) => {
  emit('select', value, fieldId)
}

const onRemove = (value: string, fieldId?: string) => {
  emit('remove', value, fieldId)
}

const onClear = () => {
  emit('clear', props.fieldId)
}
</script>
